import React from 'react';

import config from '../../config';

const Footer = () => (
    <footer id="footer">
        <ul className="icons">
            {config.socialLinks.map(social => {
                const { style, icon, name, url } = social;
                return (
                    <li key={url}>
                        <a
                            href={url}
                            className={`icon ${style} ${icon}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="label">{name}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
        <ul className="copyright">
            <li>&copy; Rock-France</li>
            <li>
                Fait avec 💚 par{' '}
                <a href={config.siteAuthor.link}>{config.siteAuthor.name}</a>
            </li>
        </ul>
    </footer>
);

export default Footer;
