import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import '../assets/sass/main.scss';
import Footer from './Footer';

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPreloaded: true,
        };
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ isPreloaded: false });
        }, 100);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        const { children, title, description, keywords, author } = this.props;
        const { isPreloaded } = this.state;

        return (
            <>
                <Helmet
                    title={title}
                    meta={[
                        {
                            name: 'description',
                            content: description,
                        },
                        {
                            name: 'keywords',
                            content: keywords.join(','),
                        },
                        {
                            name: 'author',
                            content: author,
                        },
                        {
                            name: 'google-site-verification',
                            content:
                                'KOV2kZOjgYX7gX8wjJJdknDybGXWcysDMJDLH2hNnpI',
                        },
                    ]}
                >
                    <html lang="fr" />
                </Helmet>
                <div
                    className={
                        isPreloaded
                            ? 'landing main-body is-preload'
                            : 'landing main-body'
                    }
                >
                    <div id="page-wrapper">
                        {children}
                        <Footer />
                    </div>
                </div>
            </>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    author: PropTypes.string,
};

Layout.defaultProps = {
    description: '',
    keywords: [],
    author: '',
};

export default Layout;
