const FACEBOOK_LINK = 'https://www.facebook.com/RockFrancaisLapage';
const YOUTUBE_LINK = 'https://www.youtube.com/channel/UCNnLLa4CSV7D7Z_HUqcBIAQ';

module.exports = {
    siteTitle: 'Le Rock Français des années 70 à nos jours !',
    siteDescription:
        'Passionné de Rock Français depuis plus de 30 ans, je partage tous les jours avec vous mes découvertes coup de coeur.',
    siteKeywords: ['rock', 'rock-français', 'musique'],
    siteAuthor: {
        name: 'Adrien Amoros',
        contact: 'adrien.amoros@gmail.com',
        link: 'https://www.adrienamoros.fr',
    },
    manifestName: 'Rock Français',
    manifestShortName: 'RFR', // max 12 characters
    manifestStartUrl: '/',
    manifestBackgroundColor: '#000000',
    manifestThemeColor: '#000000',
    manifestDisplay: 'standalone',
    manifestIcon: 'src/assets/images/icon-app.jpg',
    pathPrefix: ``,
    heading: 'Rock Français',
    subHeading: 'Partagez avec moi le meilleur du Rock Français',
    // Social
    facebookLink: FACEBOOK_LINK,
    youtubeLink: YOUTUBE_LINK,
    socialLinks: [
        {
            style: 'brands',
            icon: 'fa-facebook',
            name: 'Facebook',
            url: FACEBOOK_LINK,
        },
        {
            style: 'brands',
            icon: 'fa-youtube',
            name: 'YouTube',
            url: YOUTUBE_LINK,
        },
        {
            style: 'solid',
            icon: 'fa-envelope',
            name: 'Email',
            url: 'mailto:contact@rock-france.com',
        },
    ],
    // Google Analytics
    trackingId: 'UA-130810157-1',
};
